import React, { useState, useRef } from "react";
import axios from 'axios';
import { Helmet } from "react-helmet";



const SupplierRegistrationForm = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    email: "",
    location: "",
    numberOfCustomers: "",
    invoicingSystem: "",
    otherInvoicingSystem: ""
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const marketplaceSectionRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare data to submit
    const dataToSubmit = {
      ...formData,
      invoicingSystem: formData.invoicingSystem === "other" ? formData.otherInvoicingSystem : formData.invoicingSystem
    };

    axios.post('/api/register', dataToSubmit)
    .then(response => {
        console.log(response.data);
        setIsModalOpen(true); // Open the modal on successful submission
    })
    .catch(error => {
        console.error('There was an error submitting the form!', error);
    });

  };

  // Function to handle scroll
  const handleLearnMoreClick = () => {
    marketplaceSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (

  <>
      {/* Helmet for setting page title */}
      <Helmet>
        <title>Sign Up to UnitPricer</title>
      </Helmet>
    
    <div className="flex flex-col min-h-screen">

    {/* Header with logo */}
    <div className="bg-white px-4 sm:px-10 py-4 flex items-center justify-center sm:justify-start relative z-10">
      <h1 className="text-xl sm:text-2xl font-bold">
        <span className="text-black">Unit</span><span className="text-blue-600">Pricer</span>
      </h1>
    </div>

    {/* Main Content */}
    <div className="flex flex-col sm:flex-row items-center bg-fruit bg-no-repeat bg-cover bg-center">
      <div className="absolute inset-0 bg-white bg-opacity-20 w-full"></div>

      {/* Left section */}
      <div className="w-full sm:w-1/2 lg:ml-12 p-6 sm:p-12 relative z-10 rounded-xl text-center sm:text-left" style={{ backgroundColor: 'rgba(9, 28, 100, 0.86)' }}>
        <h2 className="text-3xl sm:text-5xl text-white font-bold mb-6 sm:mb-8">
          Gain Business Insights Like Never Before
        </h2>          
        <p className="text-lg sm:text-2xl text-white font-semibold mb-6 sm:mb-8">
          UnitPricer connects suppliers and businesses through a seamless, data-driven platform that streamlines communication.<br /> And the best part? It's Free.
        </p>
        <div className="text-center sm:text-left">
          <button onClick={handleLearnMoreClick} className="bg-white opacity-90 text-blue-900 text-lg sm:text-xl rounded-full py-3 sm:py-4 px-8 sm:px-10 font-bold">Learn More</button>
        </div>
      </div>

      {/* Right section (Form) */}
      <div className="w-full sm:w-1/2 flex justify-center relative z-10">
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg my-8 sm:my-16 w-full max-w-lg">
          <form onSubmit={handleSubmit} className="flex flex-col space-y-6 sm:space-y-8 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl sm:text-2xl text-center font-bold">Get Started Today</h2>
            <input placeholder="Company Name" type="text" name="companyName" value={formData.companyName} onChange={handleChange} className="p-2 w-full border rounded" required />
            <input placeholder="Email" type="email" name="email" value={formData.email} onChange={handleChange} className="p-2 w-full border rounded" required />
            <select name="numberOfCustomers" value={formData.numberOfCustomers} onChange={handleChange} className="p-2 w-full border rounded">
              <option value="">Select Number of Customers</option>
              <option value="1-10">1-10</option>
              <option value="10-50">10-50</option>
              <option value="50-100">50-100</option>
              <option value="100-500">100-500</option>
              <option value="500+">500+</option>
            </select>
            <select name="invoicingSystem" value={formData.invoicingSystem} onChange={handleChange} className="p-2 w-full border rounded">
              <option value="">Select Invoicing System</option>
              <option value="xero">Xero</option>
              <option value="myob">MYOB</option>
              <option value="quickbooks">QuickBooks</option>
              <option value="square">Square</option>
              <option value="zoho">Zoho</option>
              <option value="other">Other</option>
            </select>
            {formData.invoicingSystem === "other" && (
              <input placeholder="Please specify" type="text" name="otherInvoicingSystem" value={formData.otherInvoicingSystem} onChange={handleChange} className="p-2 w-full border rounded" />
            )}
            <button type="submit" className="bg-blue-600 hover:bg-blue-900 text-white py-2 sm:py-3 rounded font-bold mt-4">Register</button>
          </form>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-80 sm:w-96 text-center shadow-lg">
              <div className="flex justify-center items-center mb-4">
                <img src="/greentick.png" alt="Success" className="h-12 w-12" />
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-800">You're All Signed Up!</h2>
              <p className="text-gray-600 mt-2">Thank you for registering. <br /> We will be in touch within 48 hours.</p>
              <button onClick={() => setIsModalOpen(false)} className="mt-4 bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>

      <div>
        <div ref={marketplaceSectionRef} className="flex flex-wrap items-center justify-center mt-10 px-6 relative z-10 h-full section">
          {/* Left Side: Description */}
          <div className="w-full md:w-1/2 p-6 text-left">
            <h2 className="text-2xl font-semibold mb-4">Meet Australia's Fastest Growing Marketplace</h2>
            <p className="text-lg mb-4">
            Expand your reach with UnitPricer's wholesale marketplace, connecting suppliers and retailers across Australia.
            Share detailed product insights and highlight trends to rapidly boost sales. <br /> <br />
            With UnitPricer, showcase your full product range to thousands of engaged retailers, making it easier for them to 
            discover, compare, and enquire.
            </p>
          </div>

          {/* Right Side: Image */}
          <div className="w-full md:w-1/2 p-6 flex justify-center">
            {/* Image for small screens */}
            <img src="/marketplace2.png" alt="Marketplace for mobile" className="rounded-lg shadow-lg max-w-full h-full block md:hidden" />

            {/* Image for medium and larger screens */}
            <img src="/marketplace.png" alt="Marketplace" className="rounded-lg shadow-lg max-w-full h-96 hidden md:block" />
          </div>
        </div>
      

    <div className="mx-auto px-12 py-12 bg-gradient-to-br from-blue-900 via-blue-500 to-blue-900 w-full">
      <div className="grid md:grid-cols-2 gap-8">
        
        {/* First Column */}
        <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center text-center">
          <img
            src="/email.png"
            alt="Real-Time Price Notifications"
            className="rounded-lg max-w-full h-full mb-6"
          />
                    <h2 className="text-2xl font-bold mb-4 text-blue-600">Real-Time <span className="text-black">Pricing Notifications</span></h2>
          <p className="text-gray-700 mb-6">
            Boost relations with efficient price transparency. Ensure your customers are notified of all price changes in real-time, allowing for reduced pricing errors and seamless communication.
          </p>
        </div>

        {/* Second Column */}
        <div className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-center text-center">
          <img
            src="/email2.png"
            alt="Real-Time Price Notifications"
            className="rounded-lg max-w-full h-full mb-6"
          />
                    <h2 className="text-2xl font-bold mb-4 text-blue-600">Data-Based <span className="text-black">Product Recommendations</span></h2>
          <p className="text-gray-700 mb-6">
            Promote new lines, push sales and stay in touch with Unitpricer's monthly product recommendations - tailored to each customer based on advanced data insights.
          </p>
        </div>
      </div>
    </div>


  </div>

  <div>
        <div className="flex flex-wrap items-center justify-center mt-10 px-6 relative z-10 h-full">
          {/* Left Side: Description */}
          <div className="w-full md:w-1/2 p-6 flex justify-center">
            <img src="/cogs1.png" alt="cogs" className="rounded-lg shadow-lg max-w-full h-90 sm:h-full" />
          </div>

          {/* Right Side: Image */}
          <div className="w-full md:w-1/2 p-6 text-left">
            <h2 className="text-2xl font-bold mb-4">Stay on top with Weekly COGS Reports</h2>
            <p className="text-lg mb-4">
              UnitPricer gives retailers and venues access to their weekly Cost-of-Goods-Sold without the need for any accounting software or balance sheets, through <br /><strong>one simple email. </strong><br /> <br />
              Give your customers the luxury of real-time insights into their COGS, empowering them to make quick, informed decisions.<br /><br />
            </p>

            <div className="bg-green-700 rounded-xl h-full p-6">
            <h2 className="text-2xl font-bold mb-4 text-white">Settle Invoices <span className="text-white">Faster</span></h2>
            <p className="text-lg mb-4 text-white font-semibold">
              Boost cash-flow with One Click Pay. Customers are able to settle all outstanding COGS instantly, eliminating the need to chase down late payments.          </p>


            </div>
          </div>
        </div>  
  </div>

        {/* Footer */}
        <footer className="bg-blue-900 text-white py-12 mt-12">
        <div className="container mx-auto text-center">
          <p className="text-sm">
            © {new Date().getFullYear()} UnitPricer. All rights reserved.
          </p>
          <p className="text-sm mt-2">
            For inquiries, contact us at <a href="mailto:support@unitpricer.com" className="text-blue-300 underline">support@unitpricer.com</a>
          </p>
        </div>
      </footer>


    
</div>    
        
</>
  );
};

export default SupplierRegistrationForm;
