import React from "react";
import SupplierRegistrationForm from "./SupplierRegistrationForm";
import "./App.css"; // Include this for Tailwind styling setup

const App = () => {
  return (
    <div className="App">
      <SupplierRegistrationForm />
    </div>
  );
};

export default App;
